@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply font-body h-full text-dark-green break-words scroll-pt-nav-sm-height md:scroll-pt-nav-md-height scroll-smooth;
    }

    h1, h2, h3, h4, h5 {
        @apply font-display;
    }

    .article {
        @apply break-words;
        word-break: break-word;
        line-height: 1.75rem;
    }

    .article h1 {
        @apply text-3xl md:text-6xl md:pt-4 pb-4 px-4 md:px-8;
    }

    .article h2 {
        @apply text-2xl pt-8 md:text-5xl pb-4 px-4 md:px-8;
    }

    .article h3 {
        @apply text-xl pt-8 md:text-3xl pb-4 px-4 md:px-8;
    }

    .article h4 {
        @apply text-lg pt-8 pb-4 md:text-2xl px-4 md:px-8;
    }

    .article h1:first-child, .article h2:first-child, .article h3:first-child, .article h4:first-child {
        @apply pt-0;
    }

    .article p {
        @apply my-6 px-4 md:px-8;        
    }

    .article h1 + p, .article h2 + p, .article h3 + p, .article h4 + p {
        @apply mt-0;
    }

    .article ul, .article ol {
        @apply px-4 pl-12 md:px-8 md:pl-16;
    }

    .article ul li {
        @apply list-disc marker:text-primary/50;
    }

    .article ol li {
        @apply list-decimal;
    }

    .article img {
        @apply my-4 w-full md:my-8;
    }

    .article a:not(.reset-a) {
        @apply underline hover:no-underline text-danger;
    }

    .article .test-link {
        @apply border-y -mt-px py-2 no-underline border-light-grey text-danger hover:underline block mx-4 md:mx-8;
    }

    .article p .test-link {
        @apply mx-0;
    }

    .article .table-wrapper {
        @apply overflow-x-auto;
    }

    .article table {
        @apply mx-4 md:mx-8 my-4 text-xs md:text-base;
    }

    .article table th {
        @apply bg-primary text-white text-left;
    }

    .article table th, .article table td {
        @apply p-2;
    }

    .article table tr {
        @apply border-b border-light-green;
    }

    .btn {
        @apply rounded-full text-center px-8 py-2 font-medium border-0 disabled:bg-opacity-30 disabled:text-opacity-30;
        @apply focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-0 focus-visible:ring-outline-green;
    }

    .btn.btn-primary {
        @apply text-light-grey bg-primary hover:bg-primary-hover;
    }

    .btn.btn-secondary {
        @apply text-primary bg-secondary hover:bg-secondary-hover;
    }

    .btn.btn-default {
        @apply text-primary hover:bg-light-grey border border-primary hover:text-primary-hover;
    }

    .btn.btn-danger {
        @apply text-light-grey bg-danger hover:bg-danger/75;
    }

    .btn.btn-white {
        @apply text-primary bg-white hover:bg-light-green;
    }

    .btn.btn-text {
        @apply text-primary border-0;
    }

    .btn.btn-base {
        @apply text-base;
    }

    .btn.btn-sm {
        @apply text-sm;
    }

    .btn.btn-md {
        @apply text-base;
    }

    .btn.btn-lg {
        @apply text-lg;
    }
}

@layer utilities {
    .bg-radial-bottom-left {
        background-image: radial-gradient(farthest-side at 20% 80%, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

.grecaptcha-badge {
    z-index: 10;
    bottom: 80px !important;
}
